<template>
    <div class="request_customers">
        <div class="request_customers_title">Join the industry trailblazers
            already using Contrimetric</div>
        <CustomerList :isShowTitle="false" :isShowStatistics="false"></CustomerList>
    </div>
</template>

<script>
import CustomerList from "@/components/customerList/index.vue"

export default {
    components: {
        CustomerList
    },
    data() {
        return {

        }
    }
}
</script>

<style lang='scss'>
.request_customers {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .request_customers_title {
        font-size: 32px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 50px;
        color: #002f35;
        max-width: 560px;
    }
}

@media (max-width: 768px) {
    .request_customers {
        .request_customers_title {
            justify-content: center;
            font-size: 24px;
            width: 80%;
        }

        .customer_list{
            padding: 0;
        }
    }
}
</style>