<template>
    <div>
        <section class="header-bg" style="">
        </section>

        <div class="request_page_content">
            <FormVue></FormVue>
        </div>

        <div class="request_page_content">
            <CustomersVue></CustomersVue>
        </div>
    </div>
</template>

<script>
import FormVue from './requestDemo/form.vue';
import CustomersVue from './requestDemo/customers.vue';

export default {
    components: {
        FormVue,
        CustomersVue
    },
    data() {
        return {

        }
    }
}
</script>

<style lang='scss'>
.request_page_content {
    margin-top: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
}
</style>