<template>
    <div class="request_form">
        <div class="left_form">
            <div class="left_title">Harness the power of data + AI with Contrimetric</div>
            <div class="left_content">We'd love to show you what your data is capable of.</div>
            <div class="left_call">Let's chat!</div>
        </div>
        <div class="request_box_2">
            <div class="box_21">
                <div class="box21_form_title">Request a Demo</div>
                <div style="display: flex;justify-content: space-between;width: 100%;"><el-input class="elInput"
                        v-model="userName" placeholder="First Name *"></el-input>
                    <el-input v-model="userPhone" class="elInput" placeholder="Tel *"></el-input>
                </div>
                <div style="width: 100%">
                    <el-input v-model="workEmail" style="width: 100%;" placeholder="Work Email *"></el-input>
                </div>
                <div style="margin-top: 30px;"><el-checkbox v-model="isAllowSend">Send me emails about Contrimetric
                        products and updates</el-checkbox>
                    <p style="margin: 5px 0px 5px 25px;color:#000000">For complete details please visit
                        <span style="text-decoration: underline;cursor: pointer;"
                            @click="tipWorks">https://www.contrimetric.com/works</span>
                    </p>
                    <p style="margin: 5px 0px 10px 25px;color:#000000"> in touch at
                        contact@contrimetric.com
                    </p>
                </div>
                <div style="display: flex;justify-content: center;align-items: center;">
                    <el-button @click="submit">Submit</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $http from "@/request/http";

export default {
    data() {
        return {
            userName: '',
            userPhone: '',
            workEmail: '',
            isAllowSend: false
        }
    },
    methods: {
        submit() {
            let paramap = {
                userName: this.userName,
                userPhone: this.userPhone,
                userEmail: this.workEmail,
                isAllowSend: this.isAllowSend,
            }

            if (this.userName == '' || this.userPhone == '' || this.workEmail == '') {
                return this.$message.warning("Please complete the form")
            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            $http.postFhl("/journal/inserJournalForm", paramap, true).then(res => {
                if (res.code == 200) {
                    this.$message.success("Submit successfully")
                    loading.close()
                }
            }).catch(err => {
                console.log(err);
            });
        },
        tipWorks() {
            this.$router.push('/works')
        },
    }
}
</script>

<style lang='scss'>
@import "../../assets/css/minix.scss";

.request_form {
    @include responsive-width(1200px);
    display: flex;
    align-items: center;
    justify-content: space-around;

    .left_form {
        max-width: 400px;
        margin-right: 150px;
        font-weight: bold;

        .left_title {
            font-size: 42px;
        }

        .left_content {
            margin: 50px 0;
        }

        .left_content,
        .left_call {
            font-size: 19px;
        }
    }
}

.request_box_2 {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../../assets/images/no_data_form.png);
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;

    .box_21 {
        padding: 80px 0px 60px 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;

    }

    .box_21>div:nth-child(1) {
        font-family: MicrosoftTaiLe-Bold;
        font-size: 30px;
        font-weight: bold;
        font-stretch: normal;
        line-height: 26px;
        color: #000000;
        text-align: center;
    }

    .elInput {
        width: 48%;
    }

    .box_21>div:nth-child(2) {
        margin: 30px 0px 20px 0;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 19px;
        letter-spacing: 0px;
        color: #001111;
    }

    ::v-deep .box_21 .el-input__inner {
        background-color: transparent;
        border: 1px solid black;
    }

    ::v-deep .box_21 .el-checkbox__inner {
        background-color: transparent;
        border: 1px solid black;

    }

    ::v-deep .box_21 .el-checkbox {
        color: #000000;
    }

    .box_21 .el-button {
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20%;
        background-color: #1da6b8;
        border-radius: 5px;
        border: none;
        color: #fff;
        font-size: 18px;
        padding: 19px 50px 18px 50px;
    }
}

@media (max-width: 768px) {
    .request_page_content {
        margin-top: 50px !important;

        .request_form {
            flex-direction: column;
            box-sizing: border-box;
            padding: 0;

            .left_form {
                margin-right: 0;
                padding: 0 20px;

                .left_title {
                    font-size: 24px;
                }

                .left_content {
                    margin: 18px 0;
                }

                .left_content,
                .left_call {
                    font-size: 18px;
                }
            }

            .request_box_2 {
                .box_21 {
                    width: 100%;
                    padding-top: 50px;
                    padding-bottom: 10px;

                    .el-checkbox__label{
                        white-space: break-spaces;   
                    }

                    .box21_form_title {
                        font-size: 24px;
                    }

                    .el-button {
                        padding: 14px 50px;
                        font-size: 18px;
                        margin-top: 20px;
                    }
                }
            }
        }

        .request_customers {
            margin-top: 30px;
        }
    }
}
</style>